import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-f7c58996")
const _hoisted_1 = { class: "p-d-flex p-jc-center p-ai-center login-container" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoginForm = _resolveComponent("LoginForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_LoginForm, {
      onOnSubmit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSubmit($event))),
      error: _ctx.session.loginError
    }, null, 8, ["error"])
  ]))
}