
import { defineComponent } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import LoginForm from '@/components/login/LoginForm.vue'
import { useUserSessionStore } from '@/store/modules/commons/user-session-store'

export default defineComponent({
  components: {
    LoginForm
  },

  setup () {
    const session = useUserSessionStore()
    const router = useRouter()
    const route = useRoute()

    const onSubmit = ({ userName, password }: { userName: string; password: string }) => {
      session.login(userName, password)
        .then(() => {
          if (route.params.nextUrl) {
            router.push(route.params.nextUrl.toString())
          } else {
            router.push('/')
          }
        })
    }

    return {
      session,
      onSubmit
    }
  }
})
